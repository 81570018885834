@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto';
  font-size: 14px;
  letter-spacing: 0.012em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(90deg, #2e1c45 0%, #4f387d 100%);
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
	margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
