@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto';
  font-size: 14px;
  letter-spacing: 0.012em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(90deg, #2e1c45 0%, #4f387d 100%);
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
	margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
* Using !important is not a good practice, but in this case there is no other way to override the antD styles
*/

.ant-notification {
    position: fixed;
    right: auto !important;
    right: initial !important;
    left: 50%;
    top: 64px !important;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 0;
}

.ant-notification-notice-with-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
    display: flex;
    color: #5F6176;
    margin: 0 10px;
    text-align: center;
    line-height: 21px;
}

.custom-error-notification, .custom-success-notification {
    display: table;
    box-sizing: border-box;
    width: auto;
    width: initial;
    max-width: none;
    margin: 0 auto 15px auto;
    padding: 8px 16px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px -4px 4px rgba(255, 255, 255, 0.3), 0px 4px 16px rgba(235, 235, 245, 0.4);
    border-radius: 8px;
}

.custom-success-notification {
    border: 1px solid #20B56E;
}

.custom-error-notification {
    border: 1px solid #EB6178;
}

.custom-success-notification svg, .custom-error-notification svg {
    vertical-align: middle;
}

.ant-notification-notice-content {
    display: inline-flex;
    vertical-align: middle;
}

.ant-notification-notice-message {
    display: none;
}

.ant-notification-notice-icon {
    position: static;
    position: initial;
    margin: 0;
}

.ant-notification-notice-close {
    position: static;
    position: initial;
    display: inline-flex;
    vertical-align: middle;
    color: #000;
    color: initial;
    cursor: pointer;
}

